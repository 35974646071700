






import {Vue, Component} from 'vue-property-decorator';
import AideCompteMyPyrop from '@/components/Aide/AideCompteMyPyrop.vue';


@Component({
    components: {
        AideCompteMyPyrop
    }
})
export default class extends Vue {}
